<template>
  <div
    class="media-player"
    :class="{ 'rounded': rounded, 'clickable': !disabled }"
    :style="{ width: width+'px', height: height+'px' }"
    @click="click"
  >
    <v-expand-transition>
      <div
        v-if="url==null||type==2"
        class="media-container"
      >
        <v-sheet
          :class="{ 'rounded': rounded }"
          class="media-placeholder d-flex justify-center align-center"
        >
          <span class="media-placeholder-title text-overline white--text text-truncate pa-6 font-weight-regular">
            {{ controller.title }}
          </span>
        </v-sheet>
      </div>
      <div
        v-else
        class="media-container"
        @mouseenter="onHover(true)"
        @mouseleave="onHover(false)"
      >
        <video 
          v-if="type==1"
          ref="video" 
          :src="url"
          :poster="url==null ? '/img/placeholder-video.jpg' : ''"
          :autoplay="autoplay"
          muted="muted"
          playsinline
          :class="{ 'rounded': rounded }"
          class="thumb video"
          @ended="onFinish"
        />
        <v-img
          v-else
          ref="image"
          :aspect-ratio="3/1"
          :src="url"
          lazy-src="/img/placeholder-img.jpg"
          :class="{ 'rounded': rounded }"
          class="thumb image"
          @error="handleException"
        />
      </div>
    </v-expand-transition>
  </div>
</template>

<style>

  .media-player {
    line-height: 0;
  }

  .media-player.clickable {
    cursor: pointer;
  }
  
  /* .theme--light.v-application .text--secondary {
    color: white !important;
  } */

  .media-player .thumb, .media-player .media-container {
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  .media-placeholder {
    width: 100%;
    height: 100%;
    background-color: transparent !important;
    background: linear-gradient(30deg, rgba(105, 141, 242, 0.4) 0.60%, rgba(255, 143, 147, 0.32) 100%), transparent;
    color: white;
  }

  .media-player .label {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    opacity: .8;
    
  }

  .media-player .v-image__image--preload {
    filter: blur(0);
  }

</style>

<script>
  import { mdiPause } from '@mdi/js';

  export default {
    props: {
      url: {
        type: String,
        default: null
      },
      format: {
        type: String,
        default: null
      },
      title: {
        type: String,
        default: 'Mídia'
      },
      duration: {
        type: Number,
        default: 8000
      },
      width: {
        type: [String, Number],
        default: 288
      },
      rounded: {
        type: Boolean,
        default: false
      },
      pauseOnHover: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      autoplay: {
        type: Boolean,
        default: true
      },
    },
    
    data: () => ({
      icons: {
        pause: mdiPause
      },
      controller: {
        toggle: false,
        title: '',
        playback: true,
        timer: null,
        time: null,
        startedAt: null,
        remove: {
          pressed: false,
          disabled: false,
          loading: false,
        }
      },
    }),

    computed: {
      height () {
        return this.width / 3;
      },
      type () {
        const url = this.url;
        const format = this.format;
        const type = _.isNil(url) ? null : 
          format=='DSP' ? 2 : 
          format=='MP4'||(/mp4/i).test(url) ? 1 : 0;
        return type;
      },
    },

    watch: {
      title: {
        immediate: true,
        handler (title) {
          this.controller.title = this.type==2 ? 'DSP' : title;
        }
      },
      url: {
        immediate: true,
        handler (url) {
          this.controller.title = this.type==2 ? 'DSP' : this.title;
        }
      },
    },

    watch: {
      autoplay: {
        immediate: true,
        handler (b) {
          this.play(b);
        }
      }
    },

    methods: {
      play (b=true, resume=false) {
        const controller = this.controller;
        const time = !!controller.time ? controller.time : Date.now()-controller.startedAt;
        const duration = this.duration - (resume ? time : 0);
        switch (this.type) {
          case 1:
            if (this.$refs.hasOwnProperty('video')) {
              if (b) {
                this.$refs.video.play();
              }else{
                this.$refs.video.pause();
              }
            }
            break;
          default:
            if (b) {
              if (!!controller.timer) clearTimeout(controller.timer);
              controller.timer = setTimeout(() => {
                this.onFinish();
              }, duration);
            }else{
              if (!!controller.timer) clearTimeout(controller.timer);
            }
            break;
        }
        if (!resume) controller.startedAt = b ? Date.now() : null;
        if (b) {
          this.onPlay();
        }else{
          this.onPause();
        }
        this.controller.playback = b;
      },
      onPlay () {
        this.$emit('play');
      },
      onPause () {
        this.$emit('pause');
      },
      onFinish () {
        this.$emit('finish');
        this.$nextTick(() => {
          if (this.autoplay) this.play(true);
        })
      },
      onHover (b) {
        if (!this.disabled) {
          if (this.pauseOnHover) {
            this.play(!b, true);
          }
          this.$emit('hover', b);
        }
      },
      click () {
        if (!this.disabled) this.$emit('click');
      },
      handleException (error) {
        console.log(error);
      },
    },

    beforeDestroy () {
      if (!!this.controller.timer) clearTimeout(this.controller.timer);
    }
  }
</script>